import Link from "next/link";
import { Image } from "./Image";

interface Props {
  color?: "theme" | "white";
  className?: string;
}

/** ロゴ */
const DefaultLogo = ({ color, className }: Props) => {
  const src = color === "white" ? "/icons/WhiteLogo.svg" : "/icons/logo.svg";
  return (
    <Image
      tw="flex-center"
      src={src}
      width={99}
      height={18}
      layout="fixed"
      className={className}
      alt="MIROR(ミラー)"
    />
  );
};

/** トップページへのリンクになったロゴ */
const LogoLink = (props: Props) => {
  return (
    <Link href="/">
      <a>
        <DefaultLogo {...props} />
      </a>
    </Link>
  );
};

export const Logo = ({ disabled, ...rest }: Props & { disabled?: boolean }) => {
  return disabled ? <DefaultLogo {...rest} /> : <LogoLink {...rest} />;
};
