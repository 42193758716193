import { Logo } from "shared/components/atoms/Logo";
import { Paths } from "utils/routes";
import { ExternalLink } from "shared/components/atoms/ExternalLink";
import tw from "twin.macro";
import Link from "next/link";
import { FC, forwardRef } from "react";
import { currentYear } from "shared/lib/dayjs/utils";

const linkCss = tw`font-normal text-xs inline text-left text-white tracking-[-0.27px] mt-[22px]`;

const InternalLink: FC<{ href: string }> = ({ href, children }) => {
  return (
    <Link href={href} passHref>
      <a css={linkCss}>{children}</a>
    </Link>
  );
};

export const Footer = forwardRef<HTMLDivElement, {}>((_, ref) => {
  return (
    <div tw="flex flex-col p-10 bg-[#000226]" ref={ref}>
      <ExternalLink name="registerAsFortuneTeller" css={linkCss}>
        占い師登録
      </ExternalLink>
      <InternalLink href={Paths.fortuneTellers.index()}>
        占い師一覧
      </InternalLink>
      <ExternalLink name="help" css={linkCss}>
        ヘルプ
      </ExternalLink>
      <ExternalLink name="aboutSupport" css={linkCss}>
        サポート窓口
      </ExternalLink>
      <InternalLink href={Paths.terms()}>利用規約</InternalLink>
      <ExternalLink name="aboutCancel" css={linkCss}>
        キャンセルポリシー
      </ExternalLink>
      <InternalLink href={Paths.privacyPolicy()}>
        プライバシーポリシー
      </InternalLink>
      <InternalLink href={Paths.termsOfSale()}>
        特定商取引法に基づく表記
      </InternalLink>
      <ExternalLink name="aboutCompany" css={linkCss}>
        運営会社
      </ExternalLink>
      <Logo color="white" tw="flex-center mt-10" />
      <span tw="font-medium mt-4 self-center text-[9px] text-white tracking-[-0.21px]">
        © {currentYear} RAPS Inc All rights reserved.
      </span>
    </div>
  );
});

Footer.displayName = "Footer";
