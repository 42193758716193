import { memo } from "react";

const LINER_GRADIENT_ID = {
  USER_1: "USER_1",
  USER_2: "USER_2",
  USER_3: "USER_3",
};

interface Props {
  className?: string;
}

const UserIcon = memo(({ className }: Props) => {
  return (
    <svg
      height="23"
      viewBox="0 0 22 23"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <linearGradient id={LINER_GRADIENT_ID.USER_1}>
        <stop offset="0" stopColor="#B647B7" />
        <stop offset="1" stopColor="#F683B6" />
      </linearGradient>
      <linearGradient
        id={LINER_GRADIENT_ID.USER_2}
        x1="100%"
        x2="3.295688%"
        xlinkHref={`#${LINER_GRADIENT_ID.USER_1}`}
        y1="100%"
        y2="2.154082%"
      />
      <linearGradient
        id={LINER_GRADIENT_ID.USER_3}
        x1="100%"
        x2="3.295688%"
        xlinkHref={`#${LINER_GRADIENT_ID.USER_1}`}
        y1="60.136762%"
        y2="40.299947%"
      />
      <g fill="none" fillRule="evenodd" transform="translate(.333333)">
        <path
          d="m5 6c0-3.12961333 2.53705333-5.66666667 5.6666667-5.66666667 3.1296 0 5.6666666 2.53705334 5.6666666 5.66666667s-2.5370666 5.6666667-5.6666666 5.6666667c-3.12961337 0-5.6666667-2.53705337-5.6666667-5.6666667zm5.6666667-3.66666667c-2.0250667 0-3.6666667 1.64162667-3.6666667 3.66666667s1.6416 3.66666667 3.6666667 3.66666667c2.0250666 0 3.6666666-1.64162667 3.6666666-3.66666667s-1.6416-3.66666667-3.6666666-3.66666667z"
          fill={`url(#${LINER_GRADIENT_ID.USER_2})`}
        />
        <path
          d="m5.33333333 15.6666667c-1.65685333 0-3 1.3432-3 3v1.5844c0 .0241333.01748.0446666.04129334.0485333 5.49166666.8966667 11.09244003.8966667 16.58404003 0 .0238666-.0038667.0413333-.0244.0413333-.0485333v-1.5844c0-1.6568-1.3432-3-3-3h-.4545333c-.0350667 0-.07.0056-.1034667.0164l-1.154.3769333c-2.3530667.7682667-4.8896.7682667-7.24269333 0l-1.15401334-.3769333c-.0334-.0108-.06833333-.0164-.10346666-.0164zm-5 3c0-2.7614667 2.23857334-5 5-5h.45449334c.24598666 0 .49042666.0389333.72426666.1152l1.15402667.3769333c1.94974667.6365333 4.0513467.6365333 6.00108 0l1.154-.3769333c.2338667-.0762667.4782667-.1152.7242667-.1152h.4545333c2.7614667 0 5 2.2385333 5 5v1.5844c0 1.0042666-.7278667 1.8605333-1.7190667 2.0224-5.7050666.9314666-11.52346663.9314666-17.2285733 0-.99118667-.1618667-1.71902667-1.0181334-1.71902667-2.0224z"
          fill={`url(#${LINER_GRADIENT_ID.USER_3})`}
        />
      </g>
    </svg>
  );
});

export default UserIcon;
