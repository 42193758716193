import { getSession } from "next-auth/client";
import { useRouter } from "next/router";
import { FC, forwardRef, ReactNode } from "react";
import styled from "styled-components";

import { Colors } from "../../styles";
import { Badge } from "../atoms/Badge";
import { IconButton } from "../atoms/IconButton";
import { NavigationBase } from "../atoms/Navigation";

interface NavIconProps {
  icon: ReactNode;
  badgeNumber: number;
}

export const NavIcon = ({ icon, badgeNumber }: NavIconProps) => {
  return (
    <div tw="relative">
      {icon}
      {badgeNumber > 0 && (
        <Badge amount={badgeNumber} tw="absolute -top-1.5 -right-3" />
      )}
    </div>
  );
};

type Icon = FC<any> & { active?: boolean };

const Nav = styled<Icon>(IconButton)`
  width: 25%;
  font-weight: 600;
  font-size: 8px;
  color: ${({ active }) => (active ? Colors.pink["01"] : Colors.gray["01"])};
`;

interface Navigation {
  label: string;
  icon: (props: { active: boolean }) => ReactNode;
  pathname: string;
  index: number;
  authRequired?: boolean;
}

interface Props {
  navigations: Navigation[];
  activeNavIndex: number;
  authPath?: string;
}

export const AppNavigation = forwardRef<HTMLDivElement, Props>(
  ({ navigations, activeNavIndex, authPath }, ref) => {
    const router = useRouter();
    const goTo = async (nav: Navigation) => {
      if (nav.authRequired) {
        const session = await getSession();
        session
          ? router.push(nav.pathname)
          : router.push(authPath ?? "/users/sign_in");
        return;
      } else {
        router.push(nav.pathname);
      }
    };
    return (
      <NavigationBase ref={ref} tw="h-16">
        <div tw="flex items-center justify-evenly w-full">
          {navigations.map((nav) => (
            <Nav
              key={nav.label}
              icon={nav.icon({
                active: activeNavIndex === nav.index,
              })}
              onClick={() => goTo(nav)}
              active={activeNavIndex === nav.index}
            >
              <span tw="mt-0.5">{nav.label}</span>
            </Nav>
          ))}
        </div>
      </NavigationBase>
    );
  }
);

AppNavigation.displayName = "AppNavigation";
