import { Footer } from "components/layout/Footer";
import { AppHeader } from "components/layout/Header";
import { WithFlash } from "components/utils/WithFlash";
import { FC, ReactNode, useMemo, useState } from "react";
import { useClientRect, useChildClientRect } from "shared/hooks/useClientRect";
import { useFlashContext } from "shared/hooks/useFlashContext";
import { UserAppNavigation } from "./AppNavigation";
import dynamic from "next/dynamic";
import { UnpaidNotification } from "features/app/components/UnpaidNotification";
import { useUnpaidOrder } from "features/app/hooks/useUnpaidOrder";

// AppHeaderとUserAppNavigationをdynamic importするとエラーになる

const Flash = dynamic(
  async () => (await import("shared/components/atoms/Flash")).Flash
);

interface Props {
  header?: ReactNode;
  nav?: ReactNode;
  footer?: ReactNode;
  channelTalk?: ReactNode;
}

/**
 *  フラッシュを含むレイアウトUI
 */
const Layout: FC<Props> = ({
  children,
  header,
  nav,
  footer = <Footer />,
  // channelTalk = <ChannelTalkButton />,
}) => {
  const [showNotification, setShowNotification] = useState(true);

  const { unpaidOrders } = useUnpaidOrder();

  const { show, message, toggleFlash } = useFlashContext();

  const notification = useMemo(() => {
    if (unpaidOrders.length > 0) {
      return <UnpaidNotification onClose={() => setShowNotification(false)} />;
    }
  }, [unpaidOrders.length]);

  const onCloseFlash = () => toggleFlash({ show: false });

  const { height: headerHeight, ref: headerRef } = useClientRect([
    showNotification,
    notification,
  ]);
  const { height: customHeaderHeight, ref: customHeaderRef } =
    useChildClientRect([header]);
  const { height: navHeight, ref: navRef } = useChildClientRect([nav]);
  // const { height: channelTalkHeight, ref: channelTalkRef } = useClientRect([]);

  return (
    <>
      {header === undefined ? (
        <AppHeader
          ref={headerRef}
          notification={showNotification && notification}
        />
      ) : (
        <div ref={customHeaderRef}>{header}</div>
      )}
      {show && <Flash onClose={onCloseFlash}>{message}</Flash>}
      <main
        tw="overflow-x-hidden"
        css={{
          paddingTop: headerHeight || customHeaderHeight,
          // paddingBottom: footer
          //   ? navHeight
          //   : navHeight + channelTalkHeight + 32,
          paddingBottom: footer ? navHeight : navHeight + 32,
        }}
      >
        <div>
          <WithFlash>{children}</WithFlash>
          {footer}
        </div>
      </main>
      {nav === undefined ? (
        <UserAppNavigation ref={navRef} />
      ) : (
        <div ref={navRef}>{nav}</div>
      )}
      {/* {channelTalk && (
        <div
          ref={channelTalkRef}
          tw="fixed right-2.5"
          css={css({
            bottom: navHeight + 16,
            zIndex: zIndex.floating,
          })}
        >
          {channelTalk}
        </div>
      )} */}
    </>
  );
};

export default Layout;
